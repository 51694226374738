.App {
  font-size: 1rem;
}

.mainContent {
  padding: 3rem 0;
  width: 87.5%;
  max-width: 1280px;
  margin: auto;

  .row {
    display: flex;
    justify-content: space-between;

    & + .row {
      margin-top: 3rem;
    }
  }
}

@media (max-width: 692px) {
  div.row {
    flex-wrap: wrap;
    width: 100%;
    gap: 2.5rem;
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
}
