@import "../../assets/style/variables";

div.container {
  width: 100%;

  display: grid;
  column-gap: 4rem;
  row-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(16.5rem, 1fr));

  a.country,
  div.country {
    position: relative;

    width: 100%;
    padding-bottom: calc(60% + 11rem);
    background-color: var(--elements-color);

    border-radius: 5px;
    overflow: hidden;

    color: var(--text-color);
    box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.025);

    cursor: pointer;
    div.fullHeightContainer {
      position: absolute;
      width: 100%;
      height: 100%;

      display: flex;

      flex-direction: column;

      div.image,
      div.info {
        box-sizing: border-box;
        width: 100%;
      }
    }

    div.image {
      background-color: var(--elements-color);
      flex-grow: 1;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    div.info {
      height: 11rem;
      padding: 0 2rem;

      display: flex;
      flex-direction: column;
      justify-content: center;

      div.name {
        font-size: 1.125rem;
        font-weight: 800;

        margin-bottom: 1rem;
      }
      div.details {
        font-weight: 300;
        b {
          font-weight: 600;
        }
      }
    }
  }

  &.skeleton {
    div.info {
      div.name,
      div.details div {
        background-color: var(--accent-skeleton-color);
        border-radius: 10px;
      }
      div.name {
        height: 1rem;
      }
      div.details {
        margin-bottom: -0.5rem;
        div {
          height: 0.5rem;
          margin: 0.85rem 0;
        }
      }
    }
  }
}
