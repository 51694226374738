@import "../../assets/style/variables";

div.header {
  box-sizing: border-box;
  height: 3.4em;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.5em;
  font-weight: 800;

  background-color: var(--elements-color);

  div.content {
    padding: 0 6.25%;
    width: 100%;
    max-width: 1280px;
    margin: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: var(--text-color);
      text-decoration: none;
    }
    div {
      display: flex;

      button {
        padding: 1em 2em;

        border: none;
        outline: none;
        background: none;

        display: flex;
        align-items: center;

        font-weight: 600;

        border-radius: 10em;

        color: var(--text-color);

        cursor: pointer;

        &,
        span svg path {
          fill: var(--text-color);
          stroke: none;
        }

        span {
          margin-right: 1em;
        }

        &:hover {
          background: var(--text-color);
          color: var(--background-color);
          span svg path {
            fill: var(--background-color);
            stroke: none;
          }
        }
      }
    }
  }
}
