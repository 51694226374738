@import "../../assets/style/variables";

div.searchBar {
  box-sizing: border-box;
  height: 3.5em;
  width: 30em;

  margin-right: 2.5rem;
  box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.025);

  .field {
    box-sizing: border-box;
    position: relative;

    height: 100%;
    width: 100%;

    border-radius: 5px;
    overflow: hidden;

    input {
      border: none;
      outline: none;

      box-sizing: border-box;
      height: 100%;
      width: 100%;

      padding-left: 5em;

      font-weight: 600;

      background-color: var(--elements-color);
      color: var(--text-color);
      &::placeholder {
        color: var(--input-color);
      }
    }

    span {
      transform: translate(50%, 50%);
      position: absolute;
      top: 0.5em;
      left: 1.125em;
      svg path {
        fill: var(--input-color);
      }
    }
  }
}

@media (max-width: 692px) {
  div.searchBar {
    width: 100%;
    margin-right: 0;
  }
}
