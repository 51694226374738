a.button {
  box-sizing: border-box;
  height: 3.25rem;
  padding: 0 2.125rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 5px;

  text-decoration: none;

  background: var(--elements-color);
  color: var(--text-color);

  box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.025);

  span {
    display: flex;
    margin-right: 0.625rem;
    svg path {
      fill: var(--text-color);
    }
  }
}
