@import "./assets/style/fonts";
@import "./assets/style/variables";

body {
  margin: 0;
  font-family: $sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background-color);
}

button,
input {
  font-family: $sans-serif;
}
