/* nunito-sans-300 - latin */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Nunito Sans Light"), local("NunitoSans-Light"),
    url("../fonts/nunito-sans-v5-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-sans-v5-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-600 - latin */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Nunito Sans SemiBold"), local("NunitoSans-SemiBold"),
    url("../fonts/nunito-sans-v5-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-sans-v5-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-800 - latin */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  src: local("Nunito Sans ExtraBold"), local("NunitoSans-ExtraBold"),
    url("../fonts/nunito-sans-v5-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/nunito-sans-v5-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
